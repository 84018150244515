import React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';

// UI & Components
import BirdiModalContent, { BirdiModalContentAlt } from 'components/birdi-modal/BirdiModalContent/BirdiModalContent';
import { BirdiModalHeaderDanger } from 'components/birdi-modal/birdi-modal-header';
import ProfileLayout from 'components/layouts/profile/profile.layout';
import { closeModal, openModal } from 'state/birdi-modal/birdi-modal.reducers';
import Button from 'ui-kit/button/button';

//Account
import {
    accountIsLoggedInSelector,
    accountWebProfileSelector,
    accountIsSubmittingPasswordResetSelector,
    accountProfileSelector
} from 'state/account/account.selectors';
import { accountFetchWebProfileRoutine, accountSendChangePasswordRequestRoutine } from 'state/account/account.routines';

const Security = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(accountIsLoggedInSelector);
    const webProfile = useSelector(accountWebProfileSelector);
    const profileObject = useSelector(accountProfileSelector);
    const isSubmittingPasswordReset = useSelector(accountIsSubmittingPasswordResetSelector);
    const bodyText = t('pages.profile.security.changePasswordRequest.body', {
        userEmail: profileObject?.patientEmailAddress
    });

    if (isLoggedIn && webProfile === undefined) {
        dispatch(accountFetchWebProfileRoutine.trigger());
    }

    const showSendPasswordChangeSuccessModal = React.useCallback(() => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContent
                        body={t('pages.profile.security.changePasswordRequest.successModal.body')}
                        icon="default"
                        title={t('pages.profile.security.changePasswordRequest.successModal.title')}
                    />
                ),
                ctas: [
                    {
                        dataGALocation: 'PasswordChangeRequest',
                        label: t('pages.profile.security.changePasswordRequest.successModal.submit'),
                        onClick: () => {
                            dispatch(closeModal({}));
                            return false;
                        },
                        variant: 'primary'
                    }
                ],
                showClose: true
            })
        );
    }, [dispatch, t]);

    const showSendPasswordChangeErrorModal = React.useCallback(() => {
        dispatch(
            openModal({
                bodyContent: (
                    <BirdiModalContentAlt
                        subTitle={t('pages.profile.security.changePasswordRequest.failureModal.body')}
                    />
                ),
                ctas: [
                    {
                        dataGALocation: 'ForgotPasswordError',
                        label: t('pages.profile.security.changePasswordRequest.failureModal.submit'),
                        onClick: () => {
                            dispatch(closeModal({}));
                        },
                        variant: 'primary'
                    }
                ],
                headerContent: (
                    <BirdiModalHeaderDanger
                        headerText={t('pages.profile.security.changePasswordRequest.failureModal.title')}
                        icon="alert"
                    />
                ),
                showClose: true,
                type: 'danger',
                size: 'lg'
            })
        );
    }, [dispatch, t]);

    const handleFormSubmit = React.useCallback(() => {
        dispatch(
            accountSendChangePasswordRequestRoutine({
                username: webProfile?.UserName,
                onSuccess: () => {
                    showSendPasswordChangeSuccessModal();
                },
                onFailure: () => {
                    showSendPasswordChangeErrorModal();
                }
            })
        );
    }, [dispatch, showSendPasswordChangeErrorModal, showSendPasswordChangeSuccessModal, webProfile]);

    return (
        <ProfileLayout
            eyebrowText={t(`pages.profile.eyebrowText`)}
            title={t(`pages.profile.security.title`)}
            heading={t(`pages.profile.security.heading`)}
        >
            <Container>
                <Row>
                    <Col>
                        <p className="profile-form-instructions d-flex flex-column col">
                            {t('pages.profile.security.changePasswordRequest.instructions')}
                        </p>
                        <p
                            className={'mb-4'}
                            // Sanitizing to prevent issues with the innerHTML injection
                            // The <strong> tag is expected to make the email address bold.
                            dangerouslySetInnerHTML={{
                                __html: sanitizeHtml(bodyText, {
                                    allowedTags: ['strong']
                                })
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button
                            async
                            className="sm-full"
                            label={t('pages.profile.security.changePasswordRequest.button')}
                            type="submit"
                            variant="primary"
                            isBusy={isSubmittingPasswordReset}
                            onClick={handleFormSubmit}
                        />
                    </Col>
                </Row>
            </Container>
        </ProfileLayout>
    );
};

export default Security;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
